import * as React from "react"
import Layout from "../components/Layout";
import {StaticImage} from "gatsby-plugin-image";
import Headline from "../components/Headline";
import SEO from "../components/seo";

export default () => {

    return (
        <>
            <Layout>
                <section id="hero" className="grid xl:h-main">
                    <StaticImage src={'../images/hero-image.jpg'} alt="Hero Image" width={1920} loading={'eager'}
                                 className={'w-full'}></StaticImage>
                </section>
                <section>
                    <div className="container px-7.5">

                        <article className={'mt-18'}>
                            <div className={'max-w-[60ch]'}>
                                <p>Schuler Metallbau Service ist Ihr Ansprechpartner bei der Reparatur und Wartung von
                                    Fenstern,
                                    Türen sowie Brand- und Rauchschutztüren. Öffentliche Einrichtungen, wie Schulen,
                                    Behörden,
                                    Krankenhäuser, Verwaltungen und private Bauherren schätzen unsere langjährige
                                    Erfahrung
                                    und
                                    genießen unseren umfassenden Service.</p>
                            </div>
                        </article>

                        <article className={'mt-18'}>
                            <Headline title={'Reparatur'}></Headline>
                            <div className={'max-w-[60ch]'}>
                                <p>Reparaturen an Türanlagen und Fensterelementen.</p>
                            </div>
                        </article>

                        <article className={'mt-18'}>
                            <Headline title={'Prüfung'}></Headline>
                            <div className={'max-w-[60ch]'}>
                                <p>Schäden oder potentielle Defekte werden in einer fachlichen Prüfung rechtzeitig
                                    erkannt.
                                    So
                                    kann frühzeitig gehandelt werden um größere Schäden zu vermeiden.</p>
                            </div>
                        </article>

                        <article className={'flex flex-wrap items-center mt-18'}>
                            <Headline title={'Wartung'}></Headline>
                            <div className={'max-w-[60ch]'}>
                                <p className={'mt-0'}>
                                    Periodisch wiederkehrende Wartungsarbeiten an Fenstern, Türen, Brand- und
                                    Rauchschutztüren
                                    sowie Feststelleinrichtungen.
                                </p>
                                <p>
                                    Zur nachhaltigen Sicherung der Gebrauchstauglichkeit und
                                    Werthaltigkeit, als auch zur Vermeidung von Personen- und Sachschäden sowie zur
                                    Absicherung
                                    einer Haftung gegenüber Dritten ist auch während des Gewährleistungszeitraumes eine
                                    fachgerechte Wartung und Pflege erforderlich, die Sache des Bauherrn ist.
                                </p>
                                <h3 className={'font-bold'}>Wartungsverträge</h3>
                                <p>Der Wartungsvertrag stellt sicher, dass Fenster und Türen im Gebäude regelmäßig von
                                    geschultem Personal auf ihre Funktionsfähigkeit hin überprüft und bei Bedarf
                                    richtig
                                    eingestellt oder repariert werden. Die Wartung beinhaltet Überprüfungen der
                                    Oberflächen,
                                    der Dichtungen, der Beschläge und damit der allgemeinen Bedienbarkeit des Fensters
                                    oder
                                    der
                                    Türe.
                                </p>
                                <p className={'mb-0'}>
                                    Die Wartungsarbeiten, die wir Ihnen anbieten umfassen folgende
                                    Leistungen:
                                </p>
                            </div>
                            <div
                                className={'absolute translate-y-full opacity-10 xl:relative xl:translate-y-0 xl:opacity-100 ml-auto 2xl:mr-20'}>
                                <StaticImage src={'../images/icons/wheels.png'} alt={'Bild Wartung'} width={320}
                                             height={320} loading={'lazy'}>
                                </StaticImage>
                            </div>
                        </article>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="grid lg:grid-cols-2 gap-[15px] mt-18">

                            <div className={'bg-primary text-white px-7.5 py-11'}>
                                <p className={'my-0'}><b>Reinigung der beweglichen Teile</b></p>
                            </div>
                            <div className={'bg-grey hidden lg:block'}>

                            </div>

                            <div>
                                <StaticImage src={'../images/technidata.jpg'} alt={'Technidata'} className={'h-full'}
                                             loading={'lazy'}></StaticImage>
                            </div>
                            <div className={'bg-primary text-white px-7.5 py-11'}>
                                <p className={'mt-0'}><b>Überprüfung der Funktionen:</b></p>
                                <p>Selbstständiges Schließen (Schließanlage, Schließkraft)</p>
                                <p>Gängigkeit der Beschlagteile (Schloss, E-Öffner, Türbänder, Türdrucker)</p>
                                <p>Fetten der beweglichen Teile, prüfen der Spaltluft zwischen Flügel und Blendrahmen
                                    (Nachstellen der Türbänder)</p>
                                <p>Schwellendichtung oder absenkbare Dichtung (Auslösung, Verpressung der Dichtung)</p>
                                <p className={'mb-0'}>Sicherungsbolzen im Bandbereich (fester Sitz)</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container px-7.5">
                        <div className="col-span-2 pr-7.5 py-11">
                            bei Fensterelementen:<br/>
                            Ausrichtung der Flügel, wenn nötig neu verklotzen
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="grid lg:grid-cols-content gap-[15px]">

                            <div className={'bg-primary text-white px-7.5 py-11'}>
                                <p className={'my-0'}>
                                    Überprüfen der Dichtungen zwischen Flügel- und Blendrahmen, Glas und Flügelrahmen,
                                    Blendrahmen und Baukörper, ggf. nachbessern oder auswechseln der Dichtstoffe und
                                    Profile, beschädigte Dichtbänder austauschen
                                </p>
                            </div>

                            <div className="bg-grey hidden lg:block">

                            </div>

                            <div className="lg:col-span-2">
                                <StaticImage src={'../images/grid-02-a.jpg'} loading={'lazy'}
                                             alt={'Bild Frauensteige Ulm (Teil 1)'}></StaticImage>
                            </div>

                            <div className={'bg-primary text-white px-7.5 py-11'}>
                                <p className={'my-0 font-bold'}>
                                    Prüfen der Verglasungen auf Einläufe und Sprünge
                                </p>
                            </div>

                            <div className="bg-grey hidden lg:block">

                            </div>

                            <div className="lg:col-span-2">
                                <StaticImage src={'../images/grid-02-b.jpg'} loading={'lazy'}
                                             alt={'Bild Frauensteige Ulm (Teil 2)'}></StaticImage>
                            </div>

                            <div className={'bg-primary text-white px-7.5 py-11'}>
                                <p className={'my-0 font-bold'}>
                                    Empfehlung von Vorsorgereparaturen
                                </p>
                            </div>

                            <div className="bg-grey hidden lg:block">

                            </div>

                            <div className="lg:col-span-2">
                                <StaticImage src={'../images/grid-02-c.jpg'} loading={'lazy'}
                                             alt={'Bild Frauensteige Ulm (Teil 3)'}></StaticImage>
                            </div>

                            <div className={'bg-primary text-white px-7.5 py-11'}>
                                <p className={'my-0 font-bold'}>
                                    Führen eines Prüfbuches, Aufzeichnung von Umfang, Ergebnis und Zeitpunkt der
                                    jährlichen Wartung
                                </p>
                            </div>

                            <div className="bg-grey hidden lg:block">

                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className="container px-7.5">
                        <div className="flex flex-wrap items-center">
                            <div className={'w-full lg:w-3/5'}>
                                <article className={'mt-18'}>
                                    <Headline title={'Beratung'}></Headline>
                                    <div className={'max-w-[60ch]'}>
                                        <p>
                                            Wir beraten Sie gerne vor Ort und erklären Ihnen, welche Reparaturmaßnahme
                                            notwendig
                                            und sinnvoll erscheint.
                                        </p>
                                        <p>
                                            Wir sind hier in der Region für unsere Kunden direkt ansprechbar. Wir
                                            kümmern
                                            uns
                                            um Ihr Problem.
                                        </p>
                                    </div>
                                </article>

                                <article className={'mt-18'}>
                                    <Headline title={'Mitarbeiter'}></Headline>
                                    <p>
                                        Unser Service-Personal verfügt über langjährige Erfahrung und Kenntnisse<br/>
                                        im Metallbau und der Beschlagstechnik.
                                    </p>
                                    <p className={'mt-20 mb-0'}>
                                        Zertifizierungen: SCHÜCO, WICONA, GEZE, dormakaba, GU, esco
                                    </p>
                                </article>
                            </div>

                            <div className={'w-full lg:w-2/5 flex flex-wrap content-start pl-14 xl:pl-24'}>
                                <div className="hidden lg:block 2xl:max-w-[260px]">
                                    <StaticImage src={'../images/20150603_171.jpg'} alt={''} width={520}
                                                 className={'w-full'} loading={'lazy'}></StaticImage>
                                </div>
                                <div
                                    className="absolute right-7.5 -translate-y-[300px] opacity-20 md:hidden 2xl:block 2xl:relative 2xl:translate-y-0 2xl:opacity-100 2xl:right-0 2xl:m-auto">
                                    <StaticImage src={'../images/icons/tools.png'} alt={''} width={150}
                                                 className={'w-full'}
                                                 loading={'lazy'}></StaticImage>
                                </div>
                                <div className={'ml-auto -mt-10 hidden 2xl:block'}>
                                    <StaticImage src={'../images/20150603_079.jpg'} alt={''} width={415}
                                                 loading={'lazy'}></StaticImage>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </Layout>
        </>
    )
};

export const Head = () => {
    return (<SEO title={'Reparatur | Prüfung | Wartung | Beratung'}></SEO>);
}
